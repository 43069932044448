import api from "../api/axios";
import jwt_decode from "jwt-decode";
// Set all future requests to use the token.
const setToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    api.interceptors.request.use(
      async (config) => {
        config.headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          useCredentials: true,
        };
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }
};

// Get new Token by API CALL.
const tokenAPICALL = async (user) => {
  const { data } = await api.post(
    `/addlogininfo.php`,
    {
      email: user,
     // password: pass,
       // email: "ranjeet@data.in",
        //password: "Data@123",
    },
    {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    }
  );
  
 
 const dataToken = "eyJ1c2VyIjoiZGF0YWNoYXQiLCJleHAiOiIzNjAwMDAwIiwiYWxnIjoiSFMyNTYifQ.eyJ1c2VyIjoiZGF0YWNoYXQiLCJleHAiOiIxNzA1MDY1MjY2MDQ5In0.wJiSczP4n5tRbcq7O9sVqxnur8P8IgK8E058Bri9ngA";
  const jwt_Token_decoded = jwt_decode(dataToken);

  //const expiryDate = jwt_Token_decoded.exp;
  const expiryDate = jwt_Token_decoded.exp;
 
  

  if (jwt_Token_decoded.exp * 1000 > Date.now()) {
    //localStorage.setItem("token", data.token);
    localStorage.setItem("token", "0bf1e8efa0f67ee68434c193149b2012");
    localStorage.setItem("token_exp", expiryDate);
  } else {
    console.log("failed token");
  }
  
  return dataToken;
};

// Check the Token stored in Cookies.
const getToken = async (user) => {
  if(!user)
   return false;
  
  const storedJwt = localStorage.getItem("token");
  const expiration = localStorage.getItem("token_exp");
  console.log(storedJwt);
  console.log('---');
  console.log(expiration);

  if (expiration && storedJwt) {
    console.log('1111');
    if (expiration * 1000 > Date.now()) {
      console.log("Keep Token");
      setToken();
    } else {
      console.log("Expired Token");
      return await tokenAPICALL(user);
    }
  } else {
    // No token recorder in session, Get new token
    console.log("No Token");
    return await tokenAPICALL(user);
  }
};

export default getToken;

