import React, {useEffect,useRef, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import {deleteChats, getChats} from "../../repo/chatsRepo";
import { Loading } from "../templates/Loading";
import { compareAsc, format } from 'date-fns'
import {getBrains} from "../../repo/brainsRepo";
import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ScheduleTwoTone from '@material-ui/icons/ScheduleTwoTone';
import { FormControl, InputLabel, MenuItem, Select,Grid,Container,Snackbar} from '@material-ui/core';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    //height: '65vh',

  },
  dateDone: {
    fontSize:'5px',
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  MuiListItemTextPrimary :{
    width:'100%',
    wordWrap:'break-word'
  },
  messageArea: {
    height: 'calc(100vh - 271px)',    
    paddingLeft: '25px',
    paddingRight: '25px',
    scrollbarWidth: 'thin', // For Firefox
    overflowX: 'hidden',
    overflowY: 'auto', // Ensure the container has a scrollbar
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'linear-gradient(90deg, rgba(28,28,28,1) 0%, rgba(3,101,177,1) 35%, rgba(28,28,28,1) 100%)',
    },
    
   
  },
   textMessage: {
    alignItems: 'center',
    backgroundColor: '#cdd5f5',
    borderRadius: '15px 15px 0px 15px',
    boxSizing: 'border-box',    
    display: 'flex',
    maxHeight: 'none',
    minHeight: '48px',
    padding: '10px 17px',
    position: 'relative',
	  justifyContent: 'end',
    width: 'fit-content',
    float: 'right',
  },
  textMessageLeft: {
    alignItems: 'center',
    backgroundColor: '#f1f3f4',
    borderRadius: '0px 15px 15px 15px',
    boxSizing: 'border-box',
    display: 'flex',
    maxHeight: 'none',
    minHeight: '48px',
    padding: '10px 17px',
    position: 'relative',
	  justifyContent: 'start',
    width: 'fit-content',
  },
  messContainer:{
    width:'auto',

  },
  messRoot:{
    justifyContent:'end',
  },

  normalBox: {     
    color:'#ffffff',
    backgroundColor:"transparent",   
    margin: '15px',     
  },   
  button: {
    backgroundColor:'#1e293b',
    color:'#e2e8f0',       
    textAlign:'left',
    fontSize: '12px',
    textTransform:'capitalize',
    padding: '4px 10px 4px 10px',
    border:'1px solid #354458',
    '&:hover':{
      backgroundColor:'#ffffff',
      color:'#1e293b',
    }
  },
  textHeading:{
      fontWeight:'700',
      fontSize: '32px',
  },
  normalText:{
      color:'#94a3b8',
      fontSize: '14px',
      fontWeight: 'normal',
  }

});


const Chat = (props) => {
  const [loading, setLoading] = useState(false);
  const [messagetime, setMessagetime] = useState('');
  const [msg, setMsg] = useState('');
  const [messages, setMessages] = useState([]);
  const [selBrainId, setSelBrainId] = useState(props.brain_id);
  const [brainsData, setBrainsdata] = useState([]);
  const messageRef = useRef(null);
  
  useEffect(() => {
  const fetchBrain = async (query) => {
          
    try {
    
     const result = await getBrains();
     setBrainsdata(result);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  fetchBrain();

  }, []);
  
  useEffect(() => {
    
    const fetchData = async (query) => {
      
      try {

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    scrollToRecentMessage();
  }, [messages]);
  

  const scrollToRecentMessage = () => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSendMessage = async () => {

    setMessagetime(format(new Date(), 'hh:mm:ss a'));
     setMsg('');
    if (msg.trim() !== '') {
      const userMessage = { id: messages.length + 1, text: msg, isUser: true };

      // Update state with the user's message
      setMessages((prevMessages) => [...prevMessages, userMessage]);
       setMsg('');
        try {
          
          setLoading(true);
          const res = await getChats(msg,'ollama/dolphin-mistral:latest','0.8','50',selBrainId,'4944f92b-8f67-49ee-b93f-ce4e6251977e');
         
          setLoading(false);
          const replyMessage = { id: userMessage.id + 1, text: res[0].assistant, isUser: false };
          
          // Update state with the reply message
          setMessages((prevMessages) => [...prevMessages, replyMessage]);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage(); 
    }
  };
  
  const classes = useStyles();
  
  return (
      <div>
      <Container  style={{backgroundColor:'#020420', padding:'0px 15px 15px' }} maxWidth="100%" >
      <Grid container  className='chatInnerContainer' >
      <Grid item xs="8" justifyContent={"flex-start"} style={{display:'flex', alignItems:'center'}}>
      <a href=""><Button startIcon={<ScheduleTwoTone />} className={classes.button} style={{width: 'auto'}} variant={"contained"} color="primary">Clear History</Button></a>          
      </Grid>
      <Grid item xs="4" justifyContent="flex-end">            
            <FormControl fullWidth margin="normal" size="small">
              <InputLabel id="brainId-label">Select Department</InputLabel>
              <Select
                labelId="brainId-label"
                id="brainId"
                name="brain_id"
                value={selBrainId}
                input={<OutlinedInput label="Name" />} 
                onChange={(e)=>{setSelBrainId(e.target.value);}}
              >
                { brainsData.map(item => (                   
                    <MenuItem key={item.id} value={item.id}>
                        {item.name}
                    </MenuItem>
                 ))}
              </Select>
            </FormControl>
      </Grid>
      </Grid>
        <Grid container component={Paper} 
        justifyContent="center"        
        color="#94a3b8"
        width="100%"
        alignItems="center"
        style={{height:'calc(100vh - 205px)', padding:'0px', overflow:'hidden', border:'1px solid #324054', margin:'5px 0px 15px 0px',  borderRadius:'8px', backgroundColor:"#0f172a"}}
        className='chatInnerContainer'
        >            
           
                <Grid item xs={12} justifyContent='center'>
                <List className={classes.messageArea}>
                  
                {
                 messages.map(message => ( 
                  <div>

                    {message.isUser ? (
                            <ListItem className={classes.messRoot}>
                            <Grid container className={classes.messContainer} >
                                <Grid item xs={12} alignItems='flex-end'>
                                    <ListItemText className={classes.textMessage} style={{maxWidth:'88%'}} align="left" primary={`${message.text}`} key={message.id}></ListItemText>
                                </Grid>
                                <Grid item xs={12} alignItems='flex-end' >
                                    <ListItemText align="right" style={{maxWidth:'100%'}} className={classes.dateDone} secondary={`${messagetime}`} key={message.id}></ListItemText>
                                </Grid>
                            </Grid>
                        </ListItem>
                          ) : (
                            <ListItem>
                              <Grid container >
                                  <Grid item xs={12} alignItems='flex-start'>
                                      <ListItemText className={classes.textMessageLeft} style={{maxWidth:'88%'}}  align="left" primary={`${message.text}`} key={message.id}></ListItemText>
                                  </Grid>
                                  <Grid item xs={12} alignItems='flex-start'>
                                      <ListItemText align="left" className={classes.dateDone} style={{maxWidth:'100%'}} secondary={`${format(new Date(), 'hh:mm:ss a')}`} key={message.id}></ListItemText>
                                  </Grid>
                              </Grid>
                          </ListItem>
                          )}


                    
                    <div ref={messageRef}></div>
                    
                    </div>
                   
                   ))
                   } 

                </List>
                <Divider />
                
                {loading ? (
                  <Grid item xs={12} justifyContent='center'>
                  <ListItem>
                  <Grid container>
                  <Grid item xs={12} alignItems='flex-start'>
                  <ListItemText align="left" className={classes.dateDone} style={{maxWidth:'100%'}} secondary="Loading..." key="1"></ListItemText>
                  </Grid>
                  </Grid>
                  </ListItem>
                  </Grid>
                ) : (
                  <div></div>
                )}
                
                <Grid container className='chatTypeInputBox' style={{padding: '10px', backgroundColor:'#272e3f', alignItems:'center', display:'flex'}}>
                    <Grid item xs  style={{alignItems:'center'}}>
                        <TextField onKeyPress={handleKeyPress} autoComplete='off' id="chatbox" value={msg} onChange={(e)=>{setMsg(e.target.value);}}  label="Type Something" fullWidth />
                    </Grid>
                    <Grid item xs='auto' align="right" style={{alignItems:'center'}} onClick={handleSendMessage} id="sendmessgae" >
                        <SendIcon name="sendmessgae" style={{marginLeft:'10px', cursor:'pointer'}} />
                    </Grid>
                </Grid>
            </Grid>
            
        </Grid>
        </Container>
        
      </div>
  );
}

export default Chat;
