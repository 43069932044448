import React, {useEffect,useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import {deleteChats, getChatsSearch,visitorLogin,likeDislike} from "../../repo/chatsRepo";
import { Loading } from "../templates/Loading";
import { compareAsc, format } from 'date-fns';
import { Container} from '@material-ui/core';
import LogoPrivategpt from '../../img/logo-privategpt.svg';
import LoaderBrain from '../../img/LoderBrain.gif';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions,Button, IconButton, Tooltip ,FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
const useStyles = makeStyles({
  button: { 
    margin:'40px 12px 0 0',
    float:'right',
    height:'35px',
    padding:'6px !important',   
    textTransform:'capitalize',
    backgroundColor:'#334155',
    color:'#fff',
    '&:hover': {
      backgroundColor: '#25364f!important',
    },    
  }, 
  shortName:{
    margin: '0px 12px 0px 0px',
    fontSize: '18px',
    width: '30px',
    height: '30px',
    background: '#fff',
    color: '#000',
    borderRadius: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '800',
    textTransform:'uppercase'

  },
  welcomeUser:{
    float: 'right',
    color: '#fff',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px'
  },
  button1: { 
    height:'42px',  
    textTransform:'capitalize',
    backgroundColor:'#334155',
    color:'#fff',
    '&:hover': {
      backgroundColor: '#25364f!important',
    },    
  }, 
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    //height: '65vh',

  },
  dateTimeText: {
    fontSize:'8px',
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: 'calc(100vh - 245px)',    
    paddingLeft: '25px',
    paddingRight: '25px',
    scrollbarWidth: 'thin', // For Firefox
    overflowX: 'hidden',
    overflowY: 'auto', // Ensure the container has a scrollbar
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'linear-gradient(90deg, rgba(28,28,28,1) 0%, rgba(3,101,177,1) 35%, rgba(28,28,28,1) 100%)',
    },
    
   
  },
   textMessage: {
    alignItems: 'center',
    backgroundColor: '#bbe3b3',
    borderRadius: '15px 15px 0px 15px',
    boxSizing: 'border-box',
    display: 'flex',
    maxHeight: 'none',
    minHeight: '39px',
    padding: '10px 15px',
    position: 'relative',
	  justifyContent: 'end',
    width: 'fit-content',
    float: 'right',
  },
  textMessageLeft: {
    alignItems: 'center',
    backgroundColor: '#dcead9',
    borderRadius: '0px 15px 15px 15px',
    boxSizing: 'border-box',
    display: 'flex',
    maxHeight: 'none',
    minHeight: '48px',
    padding: '10px 17px',
    position: 'relative',
	  justifyContent: 'start',
    width: 'fit-content',
  },
  messContainer:{
    width:'auto',

  },
  messRoot:{
    justifyContent:'end',
  },
  chatHeading:{
    color:'white',
    textAlign: 'left',
    paddingLeft: '10px'
  },
  chatHeadingSec:{
    color:'white',
    textAlign: 'left',
    paddingLeft: '20px',
    fontSize:'16px',
    textDecoration: 'navajowhite',
  },
  chatHeadingSecChatRadio:{
    color:'white',
    textAlign: 'left',
    fontSize:'16px',
    textDecoration: 'navajowhite',
  },

  chatHeadingSec1:{
    color:'white',
    textAlign: 'left',
    paddingLeft: '10px',    
    fontSize:'16px',
    marginTop: '-20px',
    textDecoration: 'navajowhite',
    marginBottom: '-5px'
  },
  chatHeadingSec11:{
    color: 'white',
    fontSize: '16px',
    textAlign: 'left',
    textDecoration: 'navajowhite',
    paddingLeft: '10px',   
  },
  chatHeadingSec111:{
    color: 'white',
    fontSize: '16px',
    textAlign: 'left',
    textDecoration: 'navajowhite',
    marginLeft:'20px'
  },
  hideIcon: {
    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
  },
});



const PrivateChatPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [preResponse, setPreResponse] = useState(false);
  const [messagetime, setMessagetime] = useState('');
  const [msg, setMsg] = useState('');
  const [agent, setAgent] = useState(1);
  const [countStop, setCountStop] = useState(true);
  const [messages, setMessages] = useState([]);
  const [selBrainId, setSelBrainId] = useState('c2d7fbc6-3a53-4cc5-97b4-538f56bb48ea');
  const messageRef = useRef(null);
  const [companyName, setCompanyName] = useState('DATA Ingenious');
  const [typedCharacters, setTypedCharacters] = useState('');
  const [EventVal, setEventVal] = useState(0);
  const [processing, setProcessing] = useState(true);
  //const inputRef = useRef(null);
  const [likeColors, setLikeColors] = useState(Array(100000).fill('white'));
  const [disLikeColors, setDisLikeColors] = useState(Array(100000).fill('white'));
  const [topTenquestions, setToptenquestions] = useState([]);
  const [formData, setFormData] = useState({email: '',password: '' });
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loginuser, setLoginuser] = useState('');

  useEffect(() => {  
    
        
    if(countStop){
       fetchQestionData();
       const queryParams = new URLSearchParams(window.location.search);
       const agent = queryParams.get('agent');
       setToptenquestions(top10Questions);
       if(agent==3){
        setCompanyName('SemiDot INFOTECH');
        setAgent(3);
      }else if(agent==4){
        setCompanyName('Xgenplus');
        setAgent(4);
        setToptenquestions(top10Questionsxgen); 
      }else if(agent==2){
        setCompanyName('Videomeet');
        setAgent(2);
      }else if(agent==1){
        setCompanyName('DATA Ingenious');
        setAgent(1);
      }else if(agent==5){
        setCompanyName('Private AI GPT');
        setAgent(5);
        setToptenquestions(top10QuestionsAI);
      }else if(agent==8){
        setCompanyName('World Heritage India 2024');
        setAgent(8);
        setToptenquestions(top10QuestionsAI);
      }
         
    }
       
   
    scrollToRecentMessage();
    
  }, [messages,msg,countStop]);

  const fetchQestionData = () => {
   
    const queryParams = new URLSearchParams(window.location.search);
    const question = queryParams.get('question');
   // alert(count);
    setCountStop(false);
    if(question)
      handleSendMessage(2,question);
    

  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLikeDislike = async (ansId,type,squery,index) => {
    
    
    if(type==1){
      const updatedColors = [...likeColors];
      updatedColors[index] = 'green';
      setLikeColors(updatedColors);

      const updatedColors1 = [...disLikeColors];
      updatedColors1[index] = 'white';
      setDisLikeColors(updatedColors1);
    }
      
    else{
      const updatedColors = [...disLikeColors];
      updatedColors[index] = 'green';
      setDisLikeColors(updatedColors);

      const updatedColors1 = [...likeColors];
      updatedColors1[index] = 'white';
      setLikeColors(updatedColors1);

      
    }
      

    const likeDislikeRes = await likeDislike(ansId,type,squery);
  }
  
  const extractContent = (htmlString) => {
    return htmlString.replace(/<[^>]+>/g, '');
  };

  const handleCopy = (message) => {
    navigator.clipboard.writeText(extractContent(message));
  };

  const scrollToRecentMessage = () => {
    if (messageRef.current) {
      messageRef.current.scrollTo({top:0, behavior: 'smooth' });
    }
  };

  const onPasteCaptureHandler = (event) => {
      event.preventDefault();
      const textToInsert = event.clipboardData.getData('Text');
      const textarea = document.getElementById('chatbox');
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const newText =
      textarea.value.substring(0, start) +
      textToInsert +
      textarea.value.substring(end, textarea.value.length);
      setMsg(newText);
  };

  const loginSubmit = async (event) => {
    setNameError('');
    setEmailError('');
    
    
    if (username == '') {
      setNameError('Name is required');
      return false;
    }
     
    if (email == '') {
      setEmailError('Email is required');
      return false;
    }
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!isValidEmail) {
      setEmailError('Please enter a valid email address');
      return false;
    }


  const getLetters = (input) => {
      const firstLetter = input.charAt(0); // Get the first letter
      const spaceIndex = input.indexOf(' '); // Find the index of the first space
      let secondLetter = ''; // Initialize second letter
  
      // If space exists and there is a character after the space, get it
      if (spaceIndex !== -1 && spaceIndex + 1 < input.length) {
        secondLetter = input.charAt(spaceIndex + 1);
      }
  
      return firstLetter+secondLetter;
    };

    
   
    const LoginData = await visitorLogin(username,email);
   
    const shorName = getLetters(username);
    const visitorId = LoginData.data.data.id;
    sessionStorage.setItem('visitorId', visitorId);
    sessionStorage.setItem('visitorName', shorName);
    sessionStorage.setItem('fullVisitorName', username);
    setLoginuser(username);
    setOpen(false);

  };

  const logoutUser = () => {
    sessionStorage.clear();
    window.location.reload(); 
  };


  const handleSendMessage = async (EventVal,question=null) => {
   
    setMessagetime(format(new Date(), 'dd-MM-yyyy hh:mm:ss a'));
    if(!processing){
      alert('Please wait for the response...');
      return false;
    }

    if(((msg && msg.length<10) || (msg && msg.trim().split(' ').length<2)) || ((question!=null && question.length<10) || (question!=null && question.trim().split(' ').length<2) )){
      alert('Incorrect Question.');
      return false;
    }

    
    const queryParams = new URLSearchParams(window.location.search);
    const agent = queryParams.get('agent')?queryParams.get('agent'):'';

    const visitorId =  sessionStorage.getItem('visitorId');
   
    if (msg.trim() !== '' || question.trim() !=null) {
      setMsg('');
      const newmsg = (question!=null)?question:msg;
      const userMessage = { id: messages.length + 1, text: (EventVal==1 || typedCharacters=='Enter')?newmsg:newmsg+typedCharacters, isUser: true,'userTime':format(new Date(), 'dd-MM-yyyy hh:mm:ss a')  };
     
      // Update state with the user's message
      //setMessages((prevMessages) => [...prevMessages, userMessage]);
      setMessages((prevMessages) => [userMessage, ...prevMessages]);
       
        try {
          setLoading(true);
          setProcessing(false);
          const preResponseArray = ["Your response is currently being prepared......", "I am preparing your answer......", "Mind at work, please wait for my response......", "Great question, getting answer for you......"];
          const randomIndex = Math.floor(Math.random() * preResponseArray.length);
          setPreResponse(preResponseArray[randomIndex]);

          const appSessionId =  sessionStorage.getItem('appsession');
          
          if(!visitorId && !appSessionId){
            handleOpen();
             sessionStorage.setItem('appsession',1);
          }
          

          const res = await getChatsSearch((EventVal==1 || typedCharacters=='Enter')?newmsg:newmsg+typedCharacters,'ollama/lmistral','1','512',selBrainId,'62e1b61f-7cc7-4f1e-8d0c-0f2905f575c5',agent,visitorId);
          
          setEventVal(0);
          setTypedCharacters('');
          setLoading(false);    
          setProcessing(true);  
          setLikeColors(Array(100000).fill('white'));
          setDisLikeColors(Array(100000).fill('white'));
         // console.log(res);
          //const replyMessage = { id: userMessage.id + 1, text: res[0].assistant, isUser: false };
          const replyMessage = {rel: res[0].id, id: userMessage.id + 1, text: res[0].question, isUser: false,'replyTime':format(new Date(), 'dd-MM-yyyy hh:mm:ss a') };
          
          // Update state with the reply message
          //setMessages((prevMessages) => [...prevMessages, replyMessage]); 
          setMessages((prevMessages) => [replyMessage, ...prevMessages]); 
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      
    }
  };

  const handleOptionChange = (event, newValue) => {
    if (newValue) {
     
      setMsg(newValue);
    } else {
    
      setMsg(null);
    }
  };

  const handleKeyPress = (event) => {
    const pressedKey = event.key;
    setTypedCharacters(pressedKey);
   
    //setMsg(typedCharacters);
    setMsg(event.target.value);
    if (event.key === 'Enter') {
     //setEventVal(1);
      handleSendMessage(1);
      
    }
  };

  const htmlStrings =   '<strong style="color: red;">Item 1</strong> <br>* fd ghsdfg hsdflg <br>* fd ghsdfg hsdflg<br>* fd ghsdfg hsdflg<br>* fd ghsdfg hsdflg<br>* fd ghsdfg hsdflg<br>* fd ghsdfg hsdflg';


  const classes = useStyles();
  
  return (
      <div>
         {sessionStorage.getItem('visitorId')?(
         <div className={classes.welcomeUser}>
          <span onClick={logoutUser} style={{paddingRight:'5px',fontSize:'14px',fontWeight:'600','cursor':'pointer'}}> Forget Me</span>
          <span className={classes.shortName}>{sessionStorage.getItem('visitorName')}</span>
         </div>
         
         ):('')}
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Introduce Yourself</DialogTitle>
        <DialogContent className='loginform' style={{marginTop:'-39px'}}>
        
        <FormControl fullWidth margin="normal" size="small">
        <TextField
          label="Name"
          name="username"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          margin="normal"
        />
        {nameError && <div style={{ color: 'red' }}>{nameError}</div>}
        </FormControl>
        <FormControl fullWidth margin="normal" size="small">
        <TextField
          label="Email"
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          style={{marginTop:'0px',marginBottom:'25px'}}
        />
        {emailError && <div style={{ color: 'red',display:'flex' }}>{emailError}</div>}
        </FormControl>
        {/* Add more form fields as needed */}
        <Button className={classes.button1} onClick={loginSubmit} type="button" variant="contained" color="primary">Save</Button> &nbsp;<Button onClick={handleClose} style={{color:'#334155'}}>Skip</Button>
      
        </DialogContent>
       
      </Dialog>
      <div style={{alignItems:'center',display:'flex',margin:'0px 0 7px 8px'}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <rect width="35" height="35" rx="17.5" fill="#ffffff"/>
      <path d="M17.4297 23L16.6016 20.2812H12.4375L11.6094 23H9L13.0312 11.5312H15.9922L20.0391 23H17.4297ZM16.0234 18.25L15.1953 15.5938C15.1432 15.4167 15.0729 15.1901 14.9844 14.9141C14.901 14.6328 14.8151 14.349 14.7266 14.0625C14.6432 13.7708 14.5755 13.5182 14.5234 13.3047C14.4714 13.5182 14.3984 13.7839 14.3047 14.1016C14.2161 14.4141 14.1302 14.7109 14.0469 14.9922C13.9635 15.2734 13.9036 15.474 13.8672 15.5938L13.0469 18.25H16.0234ZM21.4844 23V11.5781H23.9062V23H21.4844Z" fill="#36217C"/>
      </svg>
      <h4 className={classes.chatHeadingSec}><span style={{marginLeft:'-10px',marginRight:'10px'}}>Agent</span> | <span style={{marginLeft:'10px'}}> Ask about {companyName}</span></h4></div>
      <h4 className={classes.chatHeadingSec1} >You can <Link className={classes.chatHeadingSec11} to={`/search?agent=${agent}`}><FormControlLabel value="search" label='Search' control={<Radio style={{padding: '9px 4px'}} />}  /></Link><Link className={classes.chatHeadingSecChatRadio} to="/chats"><FormControlLabel value="chat" label='Chat with me' checked control={<Radio style={{padding: '9px 4px'}} />} /></Link></h4>
      <Container maxWidth="100%"  style={{backgroundColor:'#020420', padding:'15px 15px 15px' }}>      
      <Grid className='chatTypeInputBox' style={{padding: '10px', backgroundColor:'#272e3f', alignItems:'center', display:'flex'}}>
       <Grid item xs  style={{alignItems:'center'}}>
       <Autocomplete
        freeSolo
        id="chatbox"
        disableClearable
        value={msg}
        onChange={handleOptionChange}
        options={topTenquestions.map((option) => option.title)}
        
        renderInput={(params) => (
          <TextField
            {...params}
            label="Start Your Chat Here"
            type="search"
           // inputRef={inputRef}
            
          />
        )}
        onKeyPress={handleKeyPress}
        onPaste={onPasteCaptureHandler}
        
         
      />
                        
            </Grid>
            <Grid xs='auto' align="right"  style={{alignItems:'center'}} onClick={handleSendMessage} id="sendmessgae" >
                <SendIcon name="sendmessgae" style={{marginLeft:'10px', cursor:'pointer'}} />
            </Grid>
        </Grid>
        <Grid container component={Paper}
        justifyContent="center"        
        color="#94a3b8"
        width="100%"
        alignItems="center"
        style={{height:'calc(100vh - 245px)', padding:'0px', overflow:'hidden', border:'1px solid #324054', margin:'10px 0px 0px 0px',  borderRadius:'8px', backgroundColor:"#0f172a"}}
        className='chatInnerContainer'
        > 
            {loading ? (
                  <Grid item xs={12} justifyContent='center'>
                  <ListItem >
                  <Grid container>
                  <Grid item xs={12} alignItems='flex-start' style={{ display: 'flex', alignItems: 'center' }}>                   
                  <Avatar alt="Image" src={LoaderBrain} />                  
                  <ListItemText align="left" className={classes.dateDone} style={{maxWidth:'100%', paddingLeft:'10px',fontSize:'15px!important'}} secondary={preResponse} key="1"> </ListItemText>
                  </Grid>
                  </Grid>
                  </ListItem>
                  </Grid>
                ) : (
                  <div></div>
                )}
            
            <Grid item xs={12} justifyContent='center'>
              
                <List  ref={messageRef} className={classes.messageArea}>
                {
                  messages.map((message, index) => ( 
                  <div>

                    {message.isUser ? (
                            <ListItem className={classes.messRoot}>
                              
                            <Grid style={{width:'100%'}} container className={classes.messContainer}>

                                <Grid item xs={12} alignItems='flex-end'>                                                                     
                                    <ListItemText align="left"  style={{maxWidth:'100%'}}  className={classes.textMessage} primary={<span dangerouslySetInnerHTML={{ __html: `${message.text}` }} />} key={message.id}></ListItemText>
                                                                  
                                </Grid>
                                <Grid item xs={12} alignItems='flex-end'>
                                    <ListItemText  style={{marginRight:'23px'}} align="right" className={classes.dateTimeText} secondary={message.userTime} key={message.id}></ListItemText>
                                </Grid>
                                <Tooltip title="Copy" style={{margin:'-7px -101px 0px -27px',float: 'right'}}>
                                      <IconButton aria-label="copy" className={classes.copyButton} onClick={handleCopy.bind(this, `${message.text}`)}>
                                      <svg stroke="white" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                      </IconButton>
                                    </Tooltip>
                            </Grid>
                        </ListItem>
                          ) : (
                            <ListItem>
                              <Grid container style={{width:'100%'}}>
                                  <Grid item xs={12} alignItems='flex-start'>                                 
                                      <ListItemText align="left"  style={{maxWidth:'100%'}} className={classes.textMessageLeft} primary={<span dangerouslySetInnerHTML={{ __html: `${message.text}` }} />} key={message.id}></ListItemText>
                                  </Grid>
                                  <Grid item xs={12} alignItems='flex-start'>
                                      <ListItemText className={classes.dateTimeText} align="left" secondary={message.replyTime} key={message.id}></ListItemText>
                                  </Grid>
                                  
                                  <Tooltip title="Copy" style={{margin:'-34px 0 0px 119px'}}>
                                      <IconButton aria-label="copy" className={classes.copyButton} onClick={handleCopy.bind(this, `${message.text}`)}>
                                      <svg stroke="white" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Like" style={{margin:'-34px 0 0px -15px'}}>
                                      <IconButton aria-label="copy" className={classes.copyButton}  onClick={() => handleLikeDislike(message.rel,1,2,index+10000)}>
                                      <svg   stroke={likeColors[index+10000]} fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg"><path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path></svg>
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Dislike" style={{margin:'-34px 0 0px -15px'}}>
                                      <IconButton aria-label="copy" className={classes.copyButton}  onClick={() => handleLikeDislike(message.rel,2,2,index+10000)}>
                                      <svg   stroke={disLikeColors[index+10000]} fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg"><path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path></svg>
                                      </IconButton>
                                    </Tooltip>
                              </Grid>
                          </ListItem>
                          )}


                    
                    
                    </div>
                   
                  ))
                }
 
       
         

                </List>
                <Divider />
                
            </Grid>
            
        </Grid>
      </Container>
        
      </div>
  );
}


const top10Questions = [
  { title: "आपकी कंपनी के बारे में जानकारी दे ?"},
  { title: "How to connect with your support team ?"},
  { title: 'What are contact details of your Head office ?'},
  { title: 'How to contact accounts department ?'},
  { title: 'How to apply for job ?'},
  { title: 'How to set appointment with Sales Team ?'},
  { title: 'Tell me about company services ?'},
  { title: "Can you tell me about your software products ?"}
  
];


const top10Questionsxgen = [
  { title: "How Do I Login?"},
  { title: "How Do I use Virtual Storage?"},
  { title: 'How Do I search?'},
  { title: 'How Do I apply Filters?'},
  { title: 'How to update personal details?'},
  { title: 'How Do I Block users/Domain?'},
  { title: 'How to change password?'},
  { title: "How to enable Antispam?"},
  { title: "How to set Email signature?"},
  { title: "How to manage Encryption?"}
  
];

const top10QuestionsAI = [];


export default PrivateChatPage;
