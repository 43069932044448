import React from "react";
import {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import history from './../../history';
const useStyles = makeStyles((theme) => ({
    root: {
      margin:'15px',
      backgroundColor:'#0f172a',
      color:'#ffffff',
      borderColor:'#172034',
      borderRadius:14,      
    },
    media: {
      height: 80,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
      },
    button: {
      backgroundColor:'#ffffff',
      color:'#0F172A',   
      border:'1px solid #354458',    
      textAlign:'left',
      fontSize: '12px',
      textTransform:'capitalize',
      padding: '4px 10px 4px 10px',
      '&:hover':{
        backgroundColor:'#1e293b',
        color:'#ffffff',
      }
    },
    box: {     
      padding: theme.spacing(4),
    },
    boxImg: {
      height:'42px',
      width: 'auto',
    }

      
  }));

  export default function DashboardCard(props) {
    
    const classes = useStyles();
    const [pagenmame,setPagename] = useState('dashboard')
   const handleNavigation = async (e) => {
      console.log("pagename",pagenmame);
      history.push("/"+pagenmame)
    }
    useEffect(()=>{
    console.log(props.image);
      if(props.pagename){
        setPagename(props.pagename)
      }
    },[props.pagename])
    const title = (str) => {
      return str.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
    }
    return (
        
      <Card className={classes.root}>
        <CardActionArea className={classes.box}>
        <CardMedia
          component="img"
          alt=""         
          height="auto"
          image={props.image}
          title=""
          className={classes.boxImg}
        />
           <CardContent style={{textOverflow: "ellipsis", width: '13rem'}}>
            <Typography gutterBottom variant="h6" align="left" component="h6">
            {title(props.title)}
            </Typography>
           
          </CardContent>        
        <CardActions justify={"flex-end"} alignContent={"flex-end"}>
        <Button startIcon={props.pageicon} className={classes.button} style={{width: 'auto'}} variant={"contained"} color="primary" onClick={handleNavigation} >{props.buttonname}</Button>          
        </CardActions>
        </CardActionArea>
      </Card>
      
    );
  }

