import PageTemplate from "../templates/Template";
import React from "react";
import DocumentUploadForm from './DocumentUploadForm';

const DocumentPage = (props) => {
    //console.log('api failed');
    return(
    <PageTemplate title="Add New Document">
        <DocumentUploadForm />
    </PageTemplate>)
}
export default DocumentPage;
