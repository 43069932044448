import api from "../api/chatai";



const getBrains = async (pageNo) => {
    
    let res;
    res = await getAllBrains();
    
    if (
        res &&  
        res.data &&
        res.data.brains &&
        res.data.brains.length > 0) {
            
        return res.data.brains;
        } else {
            return {
                data:[],
                total:0
            }
        }
    }

const getAllBrains = () => {
    
    return api.get(`/brains`)
    }


const uploadDocuments = async (formData,brainId) => {
  
    return api.post(`/upload?brain_id=${brainId}`, formData,{
        headers: {
            'Content-Type': 'multipart/form-data'
          },
      });
   
}

export {getBrains,getAllBrains,uploadDocuments}