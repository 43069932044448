import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import tableIcons from '../templates/TableIcons';
import getColumns from './DocumentColumns';
import Edit from "@material-ui/icons/Edit";
import {withRouter} from "react-router";
import AddBox from "@material-ui/icons/AddCircleTwoTone";
import ChatIcon from '@material-ui/icons/ChatTwoTone';
import { FormControl, InputLabel, MenuItem, Select,Grid,Container,Snackbar} from '@material-ui/core';
import {deleteDocuments, getDocuments} from "../../repo/documentsRepo";
import { Loading } from "../templates/Loading";
import {getBrains} from "../../repo/brainsRepo";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';


const useStyles = makeStyles({
  greenText: {
    color: 'green',
    marginTop: 19
  },
});

const DocumentTable = (props) => {
    const history = props.history;
    const [columns, setColumns] = useState(getColumns({}));
    const [loading, setLoading] = useState(false);
    const [brainsdata, setBrainsdata] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectedBrain, setselectedBrain] = useState(history.location.brain_id);
    const [selectedOption, setSelectedOption] = useState('');
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    
    
    const classes = useStyles();
    

    useEffect(() => {
      
      
    const fetchBrain = async (query) => {
          
      try {
      
       const result = await getBrains();
       setBrainsdata(result);
                 

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchBrain();
  }, []);

    useEffect(() => {
     
        const fetchData = async (query) => {
          
          try {
           
          
            const tbldata = await getDocuments(selectedBrain);
           
           setTableData(tbldata);
                     

          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        
        if(selectedBrain !=null)
          fetchData();
        
         
        
      }, [selectedBrain]);

      const handleSnackbarClose = () => {
        setIsSnackbarOpen(false);
      };
     
    //Here we call delete
    const handleRowDelete = (oldData, resolve,brainId) => {
    setLoading(true);
    deleteDocuments(oldData.id,brainId)
            .then(res => {
                resolve();
                setLoading(false);
                const updatedData = tableData.filter((row) => row.id !== oldData.id);
                setTableData(updatedData);
                setIsSnackbarOpen(true);
                
            })
            .catch(error => {
                resolve();
                setLoading(false);
            })
    }


    return (
    <div className='chatDocumentTablePage'>
    <Grid item xs={12} sm={9}  >
    <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity="success">
          {`Document deleted successfully.`}
        </MuiAlert>
      </Snackbar>
    <Typography className={classes.greenText} >
        {history.location.customMessage}
      </Typography>
      
    </Grid>
    <Grid container>
      
    <Grid item xs="4">
    <FormControl fullWidth  size="small">
      <InputLabel id="brainId-label">Select Department</InputLabel>      
      <Select
        labelId="brainId-label"
        id="brainId"
        name="brain_id"
        value={selectedBrain}
        input={<OutlinedInput label="Name" />}  
        onChange={(e)=>{setselectedBrain(e.target.value);}}
      >
        { brainsdata.map(item => (                   
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    </Grid>    
    </Grid>
   


   
     
    <MaterialTable     
        title="Documents Data"
        columns={columns}
        data={tableData}
        className="chatTableGrid"
        style={{marginTop:'10px'}}
        options={{            
            actionsColumnIndex: -1,
            pageSize: 4,
            toolbar: true,
            paging: true
        }}
      
        actions={[
            /*{
                icon: ()=> <Edit/>,
                tooltip: 'Edit',
                onClick: (event,rowData) =>{
                    history.push({
                        pathname:`/admins/update/${rowData.id}`,
                        user:rowData
                    })
                }
            },*/
            {
            icon: ()=><AddBox variant="contained" color="secondary"/>,
                tooltip: 'Add New Document',
                // This makes add button to appear in table toolbar instead for each row
                isFreeAction: true,
                onClick: (event, rowData) => {
                    history.push("/addDocument")
                }
            },
            {
            icon: ()=><ChatIcon variant="contained" color="secondary"/>,
                tooltip: 'PrivateChatGPT',
                // This makes add button to appear in table toolbar instead for each row
                isFreeAction: true,
                onClick: (event, rowData) => {

                    history.push({
                      pathname:"/chats",
                      brainId:selectedBrain
                  })
                }
            }
        ]}

        icons={tableIcons}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve,selectedBrain)
            }),
        }}
    
      />
   
    </div>

    );
}
export default withRouter(DocumentTable);
