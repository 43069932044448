import MaterialTable from 'material-table';
import React, {useEffect, useState} from 'react';
import tableIcons from '../templates/TableIcons';
import getColumns from './ChatColumns';
import Edit from "@material-ui/icons/Edit";
import { Switch } from "@material-ui/core";
import {withRouter} from "react-router";
import {AddBox} from "@material-ui/icons";
import {deleteChats, getChats} from "../../repo/chatsRepo";
import { Loading } from "../templates/Loading";
import BasicCard from './ChatUI';


/*
Documentation on developing the Material-Table can be found at https://material-table.com/
*/

const ChatTable = (props) => {
    const history = props.history;
    const [columns, setColumns] = useState(getColumns({}));
    const [loading, setLoading] = useState(false);
    //Here we call delete
    const handleRowDelete = (oldData, resolve) => {
    setLoading(true);
    deleteChats(oldData.id)
            .then(res => {
                resolve();
                setLoading(false);
            })
            .catch(error => {
                resolve();
                setLoading(false);
            })
    }


    return (
    <div>
    
   

    <MaterialTable
        minRows={20}
        title="Chats Data"
        columns={columns}
        data={async(query)=>
            {   
                setLoading(true);
                
                
            const res = await getChats('email of Ranjeet Singh','ollama/lmistral','1','512','f402256f-d240-4443-87ad-802a8a9a006d','62e1b61f-7cc7-4f1e-8d0c-0f2905f575c5');
           //console.log(JSON.stringify(res));
                setLoading(false);
                console.log('data loaded');
                return ({
                    
                    data: res,
                    page: query.page,
                    totalCount: 5
                })
            }
        }
        options={{
            sorting:true,
            actionsColumnIndex: -1,
            pageSize: 20,
            toolbar: true,
            paging: true
        }}

        actions={[
            {
                icon: ()=> <Edit/>,
                tooltip: 'Edit',
                onClick: (event,rowData) =>{
                    history.push({
                        pathname:`/admins/update/${rowData.id}`,
                        user:rowData
                    })
                }
            },
            {
            icon: ()=><AddBox variant="contained" color="secondary"/>,
                tooltip: 'Add New',
                // This makes add button to appear in table toolbar instead for each row
                isFreeAction: true,
                onClick: (event, rowData) => {
                    history.push("/admins/add")
                }
            }
        ]}

        icons={tableIcons}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve)
            }),
        }}

      />
    </div>);
}
export default withRouter(ChatTable);
