
import chatai from 'axios';
const apiUrl1 = 'http://10.11.13.243:5050'; //your AI base url
//const apiUrl = 'https://gpt.dil.in/api'; //your AI base url
const apiUrl = 'https://www.privategpt.in'; //your AI base url

function getHeader() {
    //const token = '0bf1e8efa0f67ee68434c193149b2012';
    const token = ''; 
    if (token) {
        
        return {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            useCredentials: true,
        };
    } else {
        return {
            'Content-Type': 'application/json',
            //'Cookie': "documentSidebarWidth=440; completed_welcome_flow=true; _ga=GA1.2.1419013985.1713849910; _ga_XXTVY8D9DP=GS1.1.1713954562.5.0.1713954562.0.0.0; fastapiusersauth=EDRZCJZ2e-Vd4CSJuRT6XUTZQlyeLFV3zGEwZC3RiLs",
            //useCredentials: true
            
        };
    }
}
const api = chatai.create({
    baseURL: apiUrl,
    headers: getHeader(),
    withCredentials: true
})



export default api;
  