import React from "react";
import {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import history from './../../history';
const useStyles = makeStyles((theme) => ({
    normalBox: {     
      color:'#ffffff',
      backgroundColor:"transparent",   
      margin: '15px',     
    },   
    button: {
      backgroundColor:'#1e293b',
      color:'#e2e8f0',       
      textAlign:'left',
      fontSize: '12px',
      textTransform:'capitalize',
      padding: '4px 10px 4px 10px',
      border:'1px solid #354458',
      '&:hover':{
        backgroundColor:'#ffffff',
        color:'#1e293b',
      }
    },
    textHeading:{
        fontWeight:'700',
        fontSize: '27px',
    },
    normalText:{
        color:'#94a3b8',
        fontSize: '14px',
        fontWeight: 'normal',
    }
  }));

  export default function DashboardCard(props) {
    
    const classes = useStyles();
    const [pagenmame,setPagename] = useState('dashboard')
   const handleNavigation = async (e) => {
      console.log("pagename",pagenmame);
      history.push("/"+pagenmame)
    }
    useEffect(()=>{
    console.log(props.image);
      if(props.pagename){
        setPagename(props.pagename)
      }
    },[props.pagename])
    const title = (str) => {
      return str.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
    }
    return (
        
      <Card className={classes.normalBox}>            
           <CardContent style={{textOverflow: "ellipsis", width: '20rem'}}>
            <Typography className={classes.textHeading} gutterBottom variant="h4" align="left">
            {title(props.title)}
            
            </Typography>  
            <Typography className={classes.normalText} align="left">A Showcase of Intelligent Solutions Transforming the Future with Artificial Intelligence and Machine Learning Expertise.</Typography>        
          </CardContent>        
        <CardActions justify={"flex-end"} alignContent={"flex-end"}>
        {/*<Button startIcon={props.pageicon} className={classes.button} style={{width: 'auto'}} variant={"contained"} color="primary" onClick={handleNavigation} >Add Document</Button> */}         
        </CardActions>       
      </Card>
      
    );
  }

