// MaterialUIPageFormCentered.js
import React, { useEffect, useState } from 'react';
import {getBrains} from "../../repo/brainsRepo";
import {Button,Input,FormControl,InputLabel,Select,MenuItem,Grid,Container, Typography,
    } from '@material-ui/core';
import {uploadDocuments} from "../../repo/brainsRepo";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


const useStyles = makeStyles((theme) => ({
  submit: {    
    textTransform:'capitalize',
    backgroundColor:'#334155',
    color:'#fff',
    '&:hover': {
      backgroundColor: '#25364f!important',
    },    
  }, 
  heading:{
    color:'#ffffff',
  }
}));


const DocumentUploadForm = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    chat_id: '1afbd93a-09eb-42ea-a90a-0c1791f2b4c5'
  });
  const [brainsData, setBrainsData] = useState([]);
  const history = useHistory();
  const [file, setFile] = useState({'name':"Upload File"});
  const [customMessage, setCustomMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getBrains();
        setBrainsData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); 


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    // Perform your API call or further processing here
    //console.log('Form Data:', formData);
    //console.log('uploadFile:', file);
    if (!file) {
        console.error('No file selected');
        return;
      }
       
      try {
      const uploadData = new FormData();
      uploadData.append('chat_id', formData.chat_id);
      //uploadData.append('brain_id', formData.brain_id);
      uploadData.append('uploadFile', file);

      
      const response =  await uploadDocuments(uploadData,formData.brain_id);
      
      //console.log("laxman");
     //console.log(response.statusText);
      if (response.statusText=='OK') {
       
        history.push({pathname:'/documents',brain_id:formData.brain_id, customMessage: 'Document uploaded successfully', });
       
        // You can handle the success response here
      } else {
        console.error('File upload failed');
        // Handle the error response here
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      // Handle the general error here
    }



  };

  return (
    <Container style={{ backgroundColor: '#020420' }} maxWidth="100%" >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        backgroundColor="#ffffff"
        alignItems="flex-start"
        style={{ height: 'calc(100vh - 113px)', paddingTop: '20px' }}
        className='chatInnerContainer'
      >
        <Grid item xs={12} sm={6}>
          <form onSubmit={handleSubmit}>
            {/* Brain Id */}
            <FormControl fullWidth margin="normal" size="small">
              <InputLabel id="brainId-label">Select Department</InputLabel>
              <Select
                labelId="brainId-label"
                id="brainId"
                name="brain_id"
                value={formData.brain_id}
                input={<OutlinedInput label="Name" />}                
                onChange={handleInputChange}
              >
                { brainsData.map(item => (                   
                    <MenuItem key={item.id} value={item.id}>
                        {item.name}
                    </MenuItem>
                 ))}
              </Select>             

            </FormControl>
            <FormControl fullWidth margin="normal">
            {/* File Upload */}
            <input
                type="file"
                accept='.doc,.docx,.odt,.pdf,.xls,.xlsx,.ods,.ppt,.pptx,.txt'
                style={{ display: 'none' }}
                id="file-upload"
                onChange={(e)=>{ setFile(e.target.files[0]); document.querySelector('.filename').innerHTML= e.target.files[0]['name'];}}
              />
              <label htmlFor="file-upload">
                <Button variant="contained"  component="span" style={{justifyContent:'flex-start'}} fullWidth startIcon={<CloudUploadIcon />}>
                 <span className="filename">{file['name']}</span> 
                </Button>             
              </label>
              </FormControl>
              <FormControl fullWidth margin="normal">
            {/* Submit Button */}
            <Button type="submit" variant="contained" className={classes.submit} color="primary" fullWidth >
              Upload
            </Button>           
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DocumentUploadForm;
