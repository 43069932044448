import PageTemplate from "../templates/Template";
import DocumentTable from "./DocumentTable";
import React from "react";
import {Grid,Container} from '@material-ui/core';

const DocumentPage = (props) => {
    //console.log('api failed');
    return(    
  <PageTemplate title="Document List" className="chatTableGrid">
    <Container maxWidth="100%" style={{backgroundColor:'#020420'}}>
      <Grid
        container               
        justifyContent="center"
        backgroundColor="#0f172a"
        color="#94a3b8"
        width="100%"
        alignItems="flex-start"
        style={{height:'calc(100vh - 112px)', padding:'20px 5px 0px', overflow:'auto'}}
        className='chatInnerContainer'
      >   
        <Grid item style={{width:'100%'}}>
            <DocumentTable />
        </Grid>     
      </Grid>
    </Container>
  </PageTemplate>
      
    )
}
export default DocumentPage;
