import React, {useEffect,useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import {deleteChats, getChats,visitorLogin,likeDislike} from "../../repo/chatsRepo";
import { Loading } from "../templates/Loading";
import { compareAsc, format } from 'date-fns';
import { Container, capitalize} from '@material-ui/core';
import LogoPrivategpt from '../../img/logo-privategpt.svg';
import LoaderBrain from '../../img/LoderBrain.gif';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typist  from 'react-typist';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions,Button, IconButton, Tooltip ,FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';


const useStyles = makeStyles({
  button: { 
    margin:'40px 12px 0 0',
    float:'right',
    height:'35px',
    padding:'6px !important', 
    textTransform:'capitalize',
    backgroundColor:'#334155',
    color:'#fff',
    '&:hover': {
      backgroundColor: '#25364f!important',
    },    
  }
  , 
  shortName:{
    margin: '0px 12px 0px 0px',
    fontSize: '18px',
    width: '30px',
    height: '30px',
    background: '#fff',
    color: '#000',
    borderRadius: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '800',
    textTransform:'uppercase'

  },
  welcomeUser:{
    float: 'right',
    color: '#fff',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px'
  },
  button1: { 
    height:'42px',  
    textTransform:'capitalize',
    backgroundColor:'#334155',
    color:'#fff',
    '&:hover': {
      backgroundColor: '#25364f!important',
    },    
  }, 
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    //height: '65vh',

  },
  dateTimeText: {
    fontSize:'8px',
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: 'calc(100vh - 245px)',    
    paddingLeft: '25px',
    paddingRight: '25px',
    scrollbarWidth: 'thin', // For Firefox
    overflowX: 'hidden',
    overflowY: 'auto', // Ensure the container has a scrollbar
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'linear-gradient(90deg, rgba(28,28,28,1) 0%, rgba(3,101,177,1) 35%, rgba(28,28,28,1) 100%)',
    },
    
  },
   textMessage: {
    alignItems: 'center',
    backgroundColor: '#cdd5f5',
    borderRadius: '15px 15px 0px 15px',
    boxSizing: 'border-box',
    display: 'flex',
    maxHeight: 'none',
    minHeight: '39px',
    padding: '10px 15px',
    position: 'relative',
	  justifyContent: 'end',
    width: 'fit-content',
    float: 'right',
  },
  textMessageLeft: {
    alignItems: 'center',
    backgroundColor: '#f1f3f4',
    borderRadius: '0px 15px 15px 15px',
    boxSizing: 'border-box',
    display: 'flex',
    maxHeight: 'none',
    minHeight: '48px',
    padding: '10px 17px',
    position: 'relative',
	  justifyContent: 'start',
    width: 'fit-content',
  },
  messContainer:{
    width:'auto',

  },
  messRoot:{
    justifyContent:'end',
  },
  chatHeading:{
    color:'white',
    textAlign: 'left',
    paddingLeft: '20px'
  },
  chatHeadingSec:{
    color:'white',
    textAlign: 'left',
    paddingLeft: '20px',
    fontSize:'16px',
    textDecoration: 'navajowhite',
  },
  chatHeadingSecChatRadio:{
    color:'white',
    textAlign: 'left',
    fontSize:'16px',
    textDecoration: 'navajowhite',
  },
  chatHeadingSec1:{
    color:'white',
    textAlign: 'left',
    paddingLeft: '10px',
    fontSize:'16px',
    marginTop: '-20px',
    textDecoration: 'navajowhite',
    marginBottom: '-5px'
  },
  chatHeadingSec11:{
    color: 'white',
    fontSize: '16px',
    textAlign: 'left',
    textDecoration: 'navajowhite',
    paddingLeft: '10px',
  },
  hideIcon: {
    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
  }

});



const PrivateChatPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(true);
  const [preResponse, setPreResponse] = useState(false);
  const [messagetime, setMessagetime] = useState('');
  const [msg, setMsg] = useState('');
  const [usermsg, setUsermsg] = useState('');
  const [messages, setMessages] = useState([]);
  const [loopmessages, setLoopMessages] = useState([]);
  const [selBrainId, setSelBrainId] = useState('c2d7fbc6-3a53-4cc5-97b4-538f56bb48ea');
  const messageRef = useRef(null);
  const inputRef = useRef(null);
  const lastchar = '';
  const [agent, setAgent] = useState(1);
  const [typedCharacters, setTypedCharacters] = useState('');
  const [EventVal, setEventVal] = useState(0);
  const [icon, setIcon] = useState('white');
  const [Qicon, setQIcon] = useState('white');
  const [copied, setCopied] = useState(false);
  const [companyName, setCompanyName] = useState('DATA Ingenious');
  const [copyColors, setCopyColors] = useState(Array(100000).fill('white'));
  const [topTenquestions, setToptenquestions] = useState([]);
  const [formData, setFormData] = useState({email: '',password: '' });
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [likeColors, setLikeColors] = useState(Array(100000).fill('white'));
  const [disLikeColors, setDisLikeColors] = useState(Array(100000).fill('white'));
  const [loginuser, setLoginuser] = useState('');

  
  useEffect(() => {   

    
    scrollToRecentMessage();
    
    const queryParams = new URLSearchParams(window.location.search);
      const agent = queryParams.get('agent');
      setToptenquestions(top10Questions);
      if(agent==3){
        setCompanyName('SemiDot INFOTECH');
        setAgent(3);
      }else if(agent==4){
        setCompanyName('Xgenplus');
        setAgent(4);
        setToptenquestions(top10Questionsxgen); 
      }else if(agent==2){
        setCompanyName('Videomeet');
        setAgent(2);
      }else if(agent==1){
        setCompanyName('DATA Ingenious');
        setAgent(1);
      }else if(agent==5){
        setCompanyName('Private AI GPT');
        setAgent(5);
        setToptenquestions(top10QuestionsAI);
      }else if(agent==8){
        setCompanyName('World Heritage India 2024');
        setAgent(8);
        setToptenquestions(top10QuestionsAI);
      }
    
      
    if (copied) {
      
      // Reset the icon to the copy icon after 1.5 seconds
      const timeout = setTimeout(() => {
        setCopyColors(Array(100000).fill('white'));
        setCopied(false);
      }, 300);
      // Clear the timeout if component unmounts before the timeout completes
      return () => clearTimeout(timeout);
    }
    
  }, [messages,msg,copied]);
  

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Submit logic here
  };

  // Function to handle form field changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  


  const scrollToRecentMessage = () => {
    if (messageRef.current) {
      messageRef.current.scrollTo({top:0, behavior: 'smooth' });
    }
  };


  const handleSendMessage = async (EventVal,msgdata=2) => {

  if(!processing){
    alert('Please wait for the response...');
    return false;
  }

  if(((msg && msg.length<10) || (msg && msg.trim().split(' ').length<2)) || ((isNaN(msgdata) && msgdata.length<10) || (isNaN(msgdata) && msgdata.trim().split(' ').length<2) )){
    alert('Incorrect Question.');
    return false;
  }

  
   const visitorId =  sessionStorage.getItem('visitorId');
   
    
    //setMessagetime(format(new Date(), 'dd-MM-yyyy hh:mm:ss a'));
    const queryParams = new URLSearchParams(window.location.search);
    const agent = queryParams.get('agent')?queryParams.get('agent'):'';
   
   
    if ((msg.trim() !== '') || msgdata.trim() !== '') {
      setMsg('');
    
      const newmsg = (msgdata!=2 && msgdata!=undefined)?msgdata:msg;
      const userMessage = { id: messages.length + 1, text:(EventVal==1 || typedCharacters=='Enter')?newmsg:newmsg+typedCharacters, isUser: true,'userTime':format(new Date(), 'dd-MM-yyyy hh:mm:ss a') };
              
     
      //alert(userMessage);
      // Update state with the user's message
      //setMessages((prevMessages) => [...prevMessages, userMessage]);
       //setMessages((prevMessages) => [userMessage, ...prevMessages]);
       setUsermsg(userMessage);
        try {
          setLoading(true);
          setProcessing(false);
          const preResponseArray = ["Your response is currently being prepared......", "I am preparing your answer......", "Mind at work, please wait for my response......", "Great question, getting answer for you......"];
          const randomIndex = Math.floor(Math.random() * preResponseArray.length);
          setPreResponse(preResponseArray[randomIndex]);
          
          const appSessionId =  sessionStorage.getItem('appsession');
          
          if(!visitorId && !appSessionId){
            handleOpen();
             sessionStorage.setItem('appsession',1);
          }
          


          const res = await getChats((EventVal==1 || typedCharacters=='Enter')?newmsg:newmsg+typedCharacters,'ollama/lmistral','1','512',selBrainId,'62e1b61f-7cc7-4f1e-8d0c-0f2905f575c5',agent,visitorId);
          setEventVal(0);
          setTypedCharacters('');
          setLoading(false); 
          setProcessing(true); 
          setLikeColors(Array(100000).fill('white'));
          setDisLikeColors(Array(100000).fill('white'));
          //console.log(res);


          //const replyMessage = { id: userMessage.id + 1, text: res[0].assistant, isUser: false };
         // const replyMessage = { id: userMessage.id + 1, text: res[0], isUser: false,'replyTime':format(new Date(), 'dd-MM-yyyy hh:mm:ss a') };
         const replyMessage = {rel: res[0].id, id: userMessage.id + 1, text: res[0].question, isUser: false,'replyTime':format(new Date(), 'dd-MM-yyyy hh:mm:ss a') };
        // const replyMessage = {rel: 55, id: userMessage.id + 1, text: "aaaaaatesting testingte stingtesti ngtest ing testing testingte stingtesti ngtest ing testing testingte stingtesti ngtest ing testing testingte stingtesti ngtest ing", isUser: false,'replyTime':format(new Date(), 'dd-MM-yyyy hh:mm:ss a') };
         const allMessage = {'userMessage':userMessage,'replyMessage':replyMessage}
          // Update state with the reply message
          //alert(JSON.stringify(allMessage));
           
           
           setMessages((prevMessages) => [allMessage,...prevMessages]);
           setUsermsg('');
           //alert(JSON.stringify(messages));
         // setLoopMessages((prevMessages) => [...prevMessages, replyMessage]);
          //setMessages((appendMessages) => [loopmessages,...appendMessages]);
          
          
         // setMessages((prevMessages) => [replyMessage, ...prevMessages]); 
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      
    }
  };

  const handleOptionChange = (event, newValue) => {
    if (newValue) {
      setMsg(newValue);
    } else {
    
      setMsg(null);
    }
  };

  const extractContent = (htmlString) => {
    return htmlString.replace(/<[^>]+>/g, '');
  };

  const handleCopy = (index,message) => {
    
    const updatedColors = [...copyColors];
    updatedColors[index] = 'gray';
    setCopyColors(updatedColors);
    navigator.clipboard.writeText(extractContent(message))
    
    setCopied(true);

  };

  const handleLikeDislike = async (ansId,type,squery,index) => {
    
    
    if(type==1){
      const updatedColors = [...likeColors];
      updatedColors[index] = 'green';
      setLikeColors(updatedColors);

      const updatedColors1 = [...disLikeColors];
      updatedColors1[index] = 'white';
      setDisLikeColors(updatedColors1);
    }
      
    else{
      const updatedColors = [...disLikeColors];
      updatedColors[index] = 'green';
      setDisLikeColors(updatedColors);

      const updatedColors1 = [...likeColors];
      updatedColors1[index] = 'white';
      setLikeColors(updatedColors1);

      
    }
      

    const likeDislikeRes = await likeDislike(ansId,type,squery);
  }


  const handleEdit = (message) => {
    inputRef.current.focus();
    //inputRef.current.value = message;
    setMsg(message);

    
    //navigator.clipboard.writeText(extractContent(message));
  };

  

  const logoutUser = () => {
    sessionStorage.clear();
    window.location.reload(); 
  };

  const handleRewrite = (message) => {
    inputRef.current.focus();
    handleSendMessage(1,message+' more detail');
  };
  
  const onPasteCaptureHandler = (event) => {
      event.preventDefault();
      const textToInsert = event.clipboardData.getData('Text');
      const textarea = document.getElementById('chatbox');
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const newText =
      textarea.value.substring(0, start) +
      textToInsert +
      textarea.value.substring(end, textarea.value.length);
      setMsg(newText);
      
      
  };
  
   const loginSubmit = async (event) => {
    setNameError('');
    setEmailError('');
    
    
    if (username == '') {
      setNameError('Name is required');
      return false;
    }
     
    if (email == '') {
      setEmailError('Email is required');
      return false;
    }
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!isValidEmail) {
      setEmailError('Please enter a valid email address');
      return false;
    }


    const getLetters = (input) => {
      const firstLetter = input.charAt(0); // Get the first letter
      const spaceIndex = input.indexOf(' '); // Find the index of the first space
      let secondLetter = ''; // Initialize second letter
  
      // If space exists and there is a character after the space, get it
      if (spaceIndex !== -1 && spaceIndex + 1 < input.length) {
        secondLetter = input.charAt(spaceIndex + 1);
      }
  
      return firstLetter+secondLetter;
    };
  
    
   
    const LoginData = await visitorLogin(username,email);
   
    const visitorId = LoginData.data.data.id;
    sessionStorage.setItem('visitorId', visitorId);

    const shorName = getLetters(username);

    sessionStorage.setItem('visitorName', shorName);
    sessionStorage.setItem('fullVisitorName', username);
    setLoginuser(username);

    setOpen(false);

  };


  
  const handleKeyPress = (event) => {
    
    const pressedKey = event.key;
    setTypedCharacters(pressedKey);
   
    //setMsg(typedCharacters);
    setMsg(event.target.value);
    if (event.key === 'Enter') {
     //setEventVal(1);
      handleSendMessage(1,2);
      
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const htmlStrings =   '<strong style="color: red;">Item 1</strong> <br>* fd ghsdfg hsdflg <br>* fd ghsdfg hsdflg<br>* fd ghsdfg hsdflg<br>* fd ghsdfg hsdflg<br>* fd ghsdfg hsdflg<br>* fd ghsdfg hsdflg';


  const classes = useStyles();
  
  return (
      <div>
     {sessionStorage.getItem('visitorId')?(
     <div className={classes.welcomeUser}>
          <span onClick={logoutUser} style={{paddingRight:'5px',fontSize:'14px',fontWeight:'600','cursor':'pointer'}}> Forget Me</span>
          <span className={classes.shortName}>{sessionStorage.getItem('visitorName')}</span>
         </div>
         
         ):('')}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Introduce Yourself</DialogTitle>
        <DialogContent className='loginform' style={{marginTop:'-39px'}}>
        
        <FormControl fullWidth margin="normal" size="small">
        <TextField
          label="Name"
          name="username"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          margin="normal"
        />
        {nameError && <div style={{ color: 'red' }}>{nameError}</div>}
        </FormControl>
        <FormControl fullWidth margin="normal" size="small">
        <TextField
          label="Email"
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          style={{marginTop:'0px',marginBottom:'25px'}}
        />
        {emailError && <div style={{ color: 'red',display:'flex' }}>{emailError}</div>}
        </FormControl>
        {/* Add more form fields as needed */}
        <Button className={classes.button1} onClick={loginSubmit} type="button" variant="contained" color="primary">Save</Button> &nbsp;<Button onClick={handleClose} style={{color:'#334155'}}>Skip</Button>
      
        </DialogContent>
       
      </Dialog>
      <h4 style={{color:'white',display:'none'}}><Link  to="/login">Login</Link></h4>
      <div style={{alignItems:'center',display:'flex',margin:'0px 0 7px 8px'}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <rect width="35" height="35" rx="17.5" fill="#ffffff"/>
      <path d="M17.4297 23L16.6016 20.2812H12.4375L11.6094 23H9L13.0312 11.5312H15.9922L20.0391 23H17.4297ZM16.0234 18.25L15.1953 15.5938C15.1432 15.4167 15.0729 15.1901 14.9844 14.9141C14.901 14.6328 14.8151 14.349 14.7266 14.0625C14.6432 13.7708 14.5755 13.5182 14.5234 13.3047C14.4714 13.5182 14.3984 13.7839 14.3047 14.1016C14.2161 14.4141 14.1302 14.7109 14.0469 14.9922C13.9635 15.2734 13.9036 15.474 13.8672 15.5938L13.0469 18.25H16.0234ZM21.4844 23V11.5781H23.9062V23H21.4844Z" fill="#36217C"/>
      </svg>
      <h4 className={classes.chatHeadingSec}><span style={{marginLeft:'-10px',marginRight:'10px'}}>Agent</span>  | <span style={{marginLeft:'10px'}}>  Ask about {companyName}</span></h4></div>
      <h4 className={classes.chatHeadingSec1} >You can <Link className={classes.chatHeadingSec11} to="/"><FormControlLabel value="search" label='Search' checked control={<Radio style={{padding: '9px 4px'}} />}  /></Link><Link className={classes.chatHeadingSecChatRadio} to={`/chats?agent=${agent}`}><FormControlLabel value="chat" label='Chat with me' control={<Radio style={{padding: '9px 4px'}} />} /></Link></h4>
      
      <Container maxWidth="100%"  style={{backgroundColor:'#020420', padding:'15px 15px 15px' }}>      
      <Grid className='chatTypeInputBox' style={{padding: '10px', backgroundColor:'#272e3f', alignItems:'center', display:'flex'}}>
       <Grid item xs  style={{alignItems:'center'}}>
       <Autocomplete
        freeSolo
        id="chatbox"
        disableClearable
        value={msg}
        onChange={handleOptionChange}
        options={topTenquestions.map((option) => option.title)}
      
        renderInput={(params) => (
          <TextField
            {...params}
            label="Ask Your Question Here"
            inputRef={inputRef}
            type="search"
          />
        )}
       
        onKeyPress={handleKeyPress}
        onPaste={onPasteCaptureHandler}
         
      />
                        
          </Grid>
          <Grid xs='auto' align="right"  style={{alignItems:'center'}}  onClick={handleSendMessage} id="sendmessgae" >
              <SendIcon name="sendmessgae" style={{marginLeft:'10px', cursor:'pointer'}} />
          </Grid>
      </Grid>
        <Grid container component={Paper}
        justifyContent="center"        
        color="#94a3b8"
        width="100%"
        alignItems="center"
        style={{height:'calc(100vh - 245px)', padding:'0px', overflow:'hidden', border:'1px solid #324054', margin:'10px 0px 0px 0px',  borderRadius:'8px', backgroundColor:"#0f172a"}}
        className='chatInnerContainer'
        > 
            
            
            <Grid item xs={12} justifyContent='center'>

                <List ref={messageRef} className={classes.messageArea}>
                {usermsg.text && (
                  <ListItem className={classes.messRoot}>
                                    
                    <Grid style={{width:'100%'}} container className={classes.messContainer}>

                        <Grid item xs={12} alignItems='flex-end'>                                                                     
                            <ListItemText align="left"  style={{maxWidth:'100%',float:'left'}}  className={classes.textMessage} primary={<span dangerouslySetInnerHTML={{ __html: `${usermsg.text}` }} />}></ListItemText>
                                                      
                        </Grid>
                        <Grid item xs={12} alignItems='flex-end'>
                            <ListItemText align="left" className={classes.dateTimeText} secondary={usermsg.userTime} key={usermsg.id}></ListItemText>
                              
                        </Grid>
                        <Tooltip title="Copy" style={{margin:'-34px 0 0px 119px'}}>
                              <IconButton aria-label="copy" className={classes.copyButton} onClick={() => handleCopy(0,usermsg.text)}>
                                <svg key={0} stroke={copyColors[0]} fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                              </IconButton>
                            </Tooltip>
                        <Tooltip title="Edit"  style={{margin:'-34px 0 0px -12px'}}>
                              <IconButton aria-label="edit" className={classes.copyButton} onClick={handleEdit.bind(this, `${usermsg.text}`)}>
                              <svg stroke="white" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="white" d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" style={{color:'white'}}></path></svg>
                              </IconButton>
                            </Tooltip>
                            
                        
                    </Grid>
                    
                  </ListItem>
                  )}

                  {loading ? (
                 
                  <Grid container>
                  <Grid item xs={12} alignItems='flex-start' style={{ display: 'flex', alignItems: 'center' }}>                   
                  <Avatar alt="Image" src={LoaderBrain} />                  
                  <ListItemText align="left" className={classes.dateDone} style={{maxWidth:'100%', paddingLeft:'10px',fontSize:'15px!important'}} secondary={preResponse} key="1"> </ListItemText>
                  </Grid>
                  </Grid>

                    ) : (
                      <div></div>
                    )}
                  
                    {
                      
                      messages.map((message, index) => ( 
                        <div>

                    
                            <ListItem className={classes.messRoot}>
                              
                            <Grid style={{width:'100%'}} container className={classes.messContainer}>

                                <Grid item xs={12} alignItems='flex-end'>                                                                     
                                    <ListItemText align="left"  style={{maxWidth:'100%',float:'left'}}  className={classes.textMessage} primary={<span dangerouslySetInnerHTML={{ __html: `${message.userMessage.text}` }} />} key={message.userMessage.id}></ListItemText>
                                                              
                                </Grid>
                                <Grid item xs={12} alignItems='flex-end'>
                                    <ListItemText align="left" className={classes.dateTimeText} secondary={message.userMessage.userTime} key={message.userMessage.id}></ListItemText>
                                     
                                </Grid>
                                <Tooltip title="Copy" style={{margin:'-34px 0 0px 119px'}}>
                                      <IconButton aria-label="copy" className={classes.copyButton} onClick={() => handleCopy(index+1,message.userMessage.text)}>
                                       <svg key={index+1} stroke={copyColors[index+1]} fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                      </IconButton>
                                    </Tooltip>
                                
                                <Tooltip title="Edit"  style={{margin:'-34px 0 0px -12px'}}>
                                  <IconButton aria-label="edit" className={classes.copyButton} onClick={handleEdit.bind(this, `${message.userMessage.text}`)}>
                                  <svg stroke="white" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="white" d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" style={{color:'white'}}></path></svg>
                                  </IconButton>
                                </Tooltip>
                                
                            </Grid>
                        </ListItem>
                          
                            <ListItem  >
                              <Grid container style={{width:'100%'}}>
                              
                                  <Grid item xs={12} alignItems='flex-start'>   
                                  <ListItemText align="left"  style={{maxWidth:'100%'}} className={classes.textMessageLeft} primary={<span dangerouslySetInnerHTML={{ __html: `${message.replyMessage.text}` }} />} key={message.replyMessage.id}></ListItemText>                              
                                    {/*index==0?<Typist avgTypingDelay={0} cursor={{ show: false }} align="left"  style={{maxWidth:'100%'}} className={classes.textMessageLeft} key={message.replyMessage.id}><span>{message.replyMessage.text}</span></Typist>:<ListItemText align="left"  style={{maxWidth:'100%'}} className={classes.textMessageLeft} primary={<span dangerouslySetInnerHTML={{ __html: `${message.replyMessage.text}` }} />} key={message.replyMessage.id}></ListItemText>*/}
                                     
                                  </Grid>
                                  
                                  
                                  <Grid item xs={12} alignItems='flex-start'>
                                  
                                    <ListItemText className={classes.dateTimeText} align="left" secondary={message.replyMessage.replyTime} key={message.replyMessage.id}></ListItemText>
                                    
                                  </Grid>
                                  
                                  
                                  <Tooltip title="Copy" style={{margin:'-34px 0 0px 119px'}}>
                                      <IconButton aria-label="copy" className={classes.copyButton}  onClick={() => handleCopy(index+10000,message.replyMessage.text)}>
                                      <svg  key={index+10000} stroke={copyColors[index+10000]} fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                      </IconButton>
                                    </Tooltip>


                                    <Tooltip title="Like" style={{margin:'-34px 0 0px -15px'}}>
                                      <IconButton aria-label="Like" className={classes.copyButton}  onClick={() => handleLikeDislike(message.replyMessage.rel,1,1,index+10000)}>
                                      <svg key={index+10000}  stroke={likeColors[index+10000]} fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg"><path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path></svg>
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Dislike" style={{margin:'-34px 0 0px -15px'}}>
                                      <IconButton aria-label="Dislike" className={classes.copyButton}  onClick={() => handleLikeDislike(message.replyMessage.rel,2,1,index+10000)}>
                                      <svg  key={index+10000} stroke={disLikeColors[index+10000]} fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg"><path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path></svg>
                                      </IconButton>
                                    </Tooltip>

                                   {/* <Tooltip title="Share"  style={{margin:'-34px 0 0px -10px'}}>
                                      <IconButton aria-label="share" className={classes.copyButton} onClick={handleCopy.bind(this, 'https://privategpt.in')}>
                                      <svg stroke="white" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="white" d="M288 240H192c-66.2 0-122 44.7-138.8 105.5C49.9 333.1 48 319.3 48 304c0-70.7 57.3-128 128-128H288h24c13.3 0 24-10.7 24-24V128 99.9L456.1 208 336 316.1V288 264c0-13.3-10.7-24-24-24H288zm0 48v48 16c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4s-19 16.6-19 29.2V80v48H240 176C78.8 128 0 206.8 0 304c0 78 38.6 126.2 68.7 152.1c4.1 3.5 8.1 6.6 11.7 9.3c3.2 2.4 6.2 4.4 8.9 6.2c4.5 3 8.3 5.1 10.8 6.5c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-6.8-3.6-13.2-8.3-18.1c-.5-.5-.9-.9-1.4-1.4c-2.4-2.3-5.1-5.1-7.7-8.6c-1.7-2.3-3.4-5-5-7.9c-5.3-9.7-9.5-22.9-9.5-40.2c0-53 43-96 96-96h48 48z"></path></svg>
                                      <div style={{fontSize:'14px',marginLeft:'6px',color:'white'}}>Share</div>
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Edit"  style={{margin:'-34px 0 0px -10px'}}>
                                      <IconButton aria-label="edit" className={classes.copyButton} onClick={handleEdit.bind(this, `${message.userMessage.text}`)}>
                                      <svg stroke="white" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="white" d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" style={{color:'white'}}></path></svg>
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Rewrite"  style={{margin:'-34px 0 0px -10px'}}>
                                      <IconButton aria-label="Rewrite" className={classes.copyButton} onClick={handleRewrite.bind(this, `${message.userMessage.text}`)}>
                                      <svg stroke="white" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="15" width="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M22.8 280C9.6 279.3-.6 268 0 254.8l.4-8C5.3 148.9 86.2 72 184.2 72H304l0-36.4C304 15.9 319.9 0 339.6 0c8.8 0 17.3 3.3 23.8 9.1l76.7 69c5.1 4.6 7.9 11 7.9 17.8s-2.9 13.3-7.9 17.8l-76.7 69c-6.5 5.9-15 9.1-23.8 9.1c-19.6 0-35.6-15.9-35.6-35.6V120H184.2C111.7 120 52 176.8 48.4 249.2l-.4 8C47.3 270.4 36 280.6 22.8 280zM352 128.5L388.1 96 352 63.5l0 65zM489.2 232c13.2 .7 23.4 11.9 22.8 25.2l-.4 8C506.7 363.1 425.8 440 327.8 440L208 440v36.4c0 19.6-15.9 35.6-35.6 35.6c-8.8 0-17.3-3.3-23.8-9.1l-76.7-69c-5.1-4.6-7.9-11-7.9-17.8s2.9-13.3 7.9-17.8l76.7-69c6.5-5.9 15-9.1 23.8-9.1c19.6 0 35.6 15.9 35.6 35.6V392l119.8 0c72.5 0 132.2-56.8 135.8-129.2l.4-8c.7-13.2 11.9-23.4 25.2-22.8zM160 383.5L123.9 416 160 448.5v-65z"  style={{color:'white'}}></path></svg>
                                      <div style={{fontSize:'14px',marginLeft:'6px',color:'white'}}>More Detail</div>
                                      </IconButton>
                                    </Tooltip>*/}
                                    
                              </Grid>
                          </ListItem>
                          

                   
                    </div>
                   
                   ))
                   } 
 
       
         

                </List>
                <Divider />
                
            </Grid>
            
        </Grid>
      </Container>
        
      


      </div>
  );
}

const top10Questions = [



  { title: "कंपनी के निदेशक कौन हैं ?"},
  { title: 'Which services company provide ?'},
  { title: 'Which technologies company can use to built software and mobile apps ?'},
  { title: 'How many number of employees company has ?'},
  { title: 'Which certifications company has  achieved ?'},
  { title: 'Can I outsource my software requirement ?'},
  { title: "Who are your top customers?"},
  { title: "Can you automate our business operations?"},
  { title: "How can I schedule a session to get better understanding of your services?"},
  { title: "What are your pricing models for software development?"},
  { title: "Who can I contact about sales inquiries?"},
  { title: "Do you offer any free trials or demos of your products?"},
  { title: "Can you reduce the cost of my operations using technology? "},
  { title: "Tell me more about Data Ingenious."}
  
];


const top10Questionsxgen = [
  { title: "How Do I Login?"},
  { title: "How Do I use Virtual Storage?"},
  { title: 'How Do I search?'},
  { title: 'How Do I apply Filters?'},
  { title: 'How to update personal details?'},
  { title: 'How Do I Block users/Domain?'},
  { title: 'How to change password?'},
  { title: "How to enable Antispam?"},
  { title: "How to set Email signature?"},
  { title: "How to manage Encryption?"}
  
];

const top10QuestionsAI = [];


export default PrivateChatPage;
