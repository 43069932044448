import React, { useEffect, useState } from "react";
import "./App.css";
//import setToken from "./auth/auth";
import Routes from "./Routes";
import { createContext } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core";
import ReactSwitch from "react-switch";

export const ThemeContext = createContext(null);



function App() {
  console.disableYellowBox = true;
  useEffect(() => {
    //setToken();
  }, []);
  const [theme, setTheme] = useState("dark");
  const toggleTheme = () => {
    setTheme ((curr) => (curr === "light" ? "dark" : "light"));
  };

  const fontTheme = createTheme({
    typography: {
      fontFamily: [
        '"DM Sans"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
  });
  return (
    <ThemeProvider value={{ theme, toggleTheme }} theme={fontTheme}>
      <div className="App" id={theme}>
        <Routes />
        <div className="themeSwitch" style={{display:"none"}}>
        <ReactSwitch onChange={toggleTheme} checked={theme === "dark"}  />
        </div>
      </div>
    </ThemeProvider>
  );
}


export default App;

