import PageTemplate from "../templates/Template";
import ChatTable from "./ChatTable";
import React from "react";
import BasicCard from './LiveSearchUI';

const ChatPage = (props) => {
    
    return(
    <PageTemplate>
        
       <BasicCard brain_id={props.location.brainId ? (props.location.brainId):("c2d7fbc6-3a53-4cc5-97b4-538f56bb48ea")}/>
    </PageTemplate>)
}
export default ChatPage;
