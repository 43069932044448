import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TableChartIcon from '@material-ui/icons/TableChart';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ChatIcon from '@material-ui/icons/Chat';
import history from './../../history';
import {AddBox} from "@material-ui/icons";



export const mainListItems = (
  <div>
    <ListItem button onClick={() => history.push('/dashboard')}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
    </ListItem>

     {window.location.pathname=='/Documents' || window.location.pathname=='/chats'|| window.location.pathname=='/addDocument' ? (
        <>
        
        <ListItem button onClick={() => history.push('/documents')}>
        <ListItemIcon><TableChartIcon /></ListItemIcon>
        <ListItemText primary="Documents List" />
        </ListItem>
        <ListItem button onClick={() => history.push('/chats')}>
          <ListItemIcon><ChatIcon/></ListItemIcon>
          <ListItemText primary="DocumentChat" />
        </ListItem>

        <ListItem button onClick={() => history.push('/addDocument')}>
            <ListItemIcon><AddBox/></ListItemIcon>
            <ListItemText primary="Upload Documents" />
        </ListItem>
      </>
      ) : window.location.pathname=='/privateGPT'?(
      <ListItem button onClick={() => history.push('/chats')}>
          <ListItemIcon><ChatIcon/></ListItemIcon>
          <ListItemText primary="PrivateChatGPT" />
      </ListItem>):(<></>
      )

      }

    
    
    


  </div>
  /*<ListItem button onClick={() => history.push('/documents')}>
  <ListItemIcon><TableChartIcon /></ListItemIcon>
  <ListItemText primary="Documents" />
  </ListItem>
  
  <ListItem button onClick={() => history.push('/chats')}>
  <ListItemIcon><ChatIcon/></ListItemIcon>
  <ListItemText primary="PrivateChatGPT" />
  </ListItem>*/
);

export const secondaryListItems = (
  <div>
    <ListItem button onClick={() =>{ localStorage.setItem("token_exp",0);history.push('/')}}>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
   
  </div>
);
