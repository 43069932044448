import api from "../api/chatai";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getChats = async (question,model,temperature,max_tokens,brain_id,prompt_id,agent,visitorId) => {
    
    let res;
    
    let result = await getAllChats(question,model,temperature,max_tokens,brain_id,prompt_id,agent,visitorId);
    res = Array(result.data); 
    

    if (
    res && 
    res.length > 0) {
    return res;
    } else {
        return {
            data:[],
            total:0
        }
    }
}

const getChatsSearch = async (question,model,temperature,max_tokens,brain_id,prompt_id,agent,visitorId) => {
    
    let res;
    
    let result = await getAllChatsSearch(question,model,temperature,max_tokens,brain_id,prompt_id,agent,visitorId);
    res = Array(result.data); 
    

    if (
    res && 
    res.length > 0) {
    return res;
    } else {
        return {
            data:[],
            total:0
        }
    }
}




const addAdmins = (data) => {
return api.post(`/admins`,data)
}
const updateAdmins = (id,data) => {
return api.put(`/admins/${id}`,data)
}

const getAllChats = (question,model,temperature,max_tokens,brain_id,prompt_id,agent,visitorId) => {

    
    const data = {"messages":[{"message":question,'agent':agent,'visitor_id':visitorId}]}

    return api.post(`/curl.php`,data)
    }

const getAllChatsSearch = (question,model,temperature,max_tokens,brain_id,prompt_id,agent,visitorId) => {


    const data = {"messages":[{"message":question,"agent":agent,'visitor_id':visitorId}]}

    return api.post(`/chat_request.php`,data)
    }  
    
      

const visitorLogin = (username,email) => {


const data = {"messages":[{"name":username,'email':email}]}

return api.post(`/visitors.php`,data)
} 

const likeDislike = (ansId,type,search_query) => {


const data = {"messages":[{"answer_id":ansId,'type':type,'search_query':search_query}]}

return api.post(`/likeDislike.php`,data);

}

const getLiveSearch = (selectedOption=null,selectedDate=null) => {


    const data = {"messages":[{"data_type":"search_queries","start_id":0,"date_for":selectedDate,'agent':selectedOption}]}

    return api.post(`/list.php`,data)
 } 

const getLiveChat = (selectedOption=null,selectedDate=null) => {

    const data = {"messages":[{"data_type":"chat_queries","start_id":0,"date_for":selectedDate,'agent':selectedOption}]}
        //const data = {"messages":[{"data_type":"chat_queries","start_id":0,"date_for":''}]}
    
        return api.post(`/list.php`,data)
 } 

const getOneAdmins = (id) => {
return api.get(`/admins/${id}`)
}
const searchDocument = (page,paginator,searchKey) => {
return api.get(`/admins/search/${searchKey}/?page=${page}&paginator=${paginator}`)
}
const deleteChats = (id) => {
return api.delete(`/admins/${id}`)
}
export {getChats,getAllChatsSearch,getChatsSearch,addAdmins,updateAdmins,getAllChats,getOneAdmins,searchDocument,deleteChats,getLiveSearch,getLiveChat,visitorLogin,likeDislike}


