import React from "react";
import { Loading } from "./templates/Loading";
const test = ()=>
{
    return (
        
           
            <Loading />
       )
  
}
  export default test;