import PageTemplate from "../templates/Template";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DashCard from "./DashboardCard";
import DashCardSimple from "./DashboardCardSimple";
import dashIcon01 from '../../img/icon01.svg';
import dashIcon02 from '../../img/icon02.svg';
import ChatTwoTone from '@material-ui/icons/ChatTwoTone';
import FolderTwoTone from '@material-ui/icons/FolderTwoTone';
import AddCircle from '@material-ui/icons/AddCircleTwoTone';
import dashBg from '../../img/Group-1000004157.png';

const DashboardPage = () => {

return(
<PageTemplate title="Dashboard" >
<Grid container maxWidth="100%"  direction="row"  alignItems="flex-start" style={{height:'calc(100vh - 112px)', justifyContent: 'center', paddingTop:'20px', overflow:'auto', background:`#020420 url(${dashBg}) no-repeat center center`}}>
<DashCardSimple  pagename='addDocument'  title="Xgen AI ML Dashboard - AI & ML Innovations at Your Fingertips"  pageicon={<AddCircle />}/>
<DashCard pagename='chats'  buttonname='Go' image={dashIcon02} title="Documents Chat"  pageicon={<FolderTwoTone />}/>
<DashCard pagename='privatechats'  buttonname='Go' image={dashIcon01} title="DataChatGPT" pageicon={<ChatTwoTone />} />
</Grid>
</PageTemplate>)
}
export default DashboardPage;

