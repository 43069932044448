import React, {useEffect,useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems, secondaryListItems } from './MenuItems';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ChatIcon from '@material-ui/icons/Chat';
import history from './../../history';
import {AddBox} from "@material-ui/icons";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TableChartIcon from '@material-ui/icons/TableChart';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Iconnuxt from '../../img/icon1.svg';
import Icondiscord from '../../img/icon2.svg';
import Icongithub from '../../img/icon3.svg';
import LogoPrivategpt from '../../img/logo-privategpt.svg';
function Copyright() {
  return ( 
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link target='_blank' href="https://www.dil.in/" color="inherit">
        Data Ingenious Global Limited
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));

export default function PageTemplate(props) {
  const [isActive, setIsActive] = useState(false);  
  const [currentPath, setCurrentPath] = useState('');
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);   
    if (isActive) {
      setIsActive(false); // Set isClicked to true only if it's false
    } 
  };
  const handleDrawerClose = () => {
    setOpen(false);
    if (!isActive) {
      setIsActive(true); // Set isClicked to true only if it's false
    }
  };



  useEffect(() => {
    // Get the current pathname from the window location
    const pathname = window.location.pathname;
    setCurrentPath(pathname);
    //console.log(pathname);
  }, []); // Run the effect only once during component mount

  // Function to determine if a link should be active
  
  return (
    /*<IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>*/
    <div className={classes.root}>
     
      
      <Drawer>
        <div className={classes.toolbarIcon}>
        <img src={LogoPrivategpt} alt="logo"/>
          <IconButton className='test' onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
       

     {currentPath=='/documents' || currentPath=='/Documents' || currentPath=='/chats'|| currentPath=='/addDocument' ? (
        <>
        
        <ListItem className={('/documents' == currentPath) ? 'active' : ''} button onClick={() => history.push('/documents')}>
        <ListItemIcon><TableChartIcon /></ListItemIcon>
        <ListItemText primary="Documents List" />
        </ListItem>
        <ListItem className={('/chats' == currentPath) ? 'active' : ''} button onClick={() => history.push('/chats')}>
          <ListItemIcon><ChatIcon/></ListItemIcon>
          <ListItemText primary="Chat" />
        </ListItem>

        <ListItem className={('/addDocument' == currentPath) ? 'active' : ''} button onClick={() => history.push('/addDocument')}>
            <ListItemIcon><AddBox/></ListItemIcon>
            <ListItemText primary="Upload Documents" />
        </ListItem>
      </>
      ) : window.location.pathname=='/privatechats'?(
      <ListItem className={('/privatechats' == currentPath) ? 'active' : ''} button onClick={() => history.push('/privatechats')}>
          <ListItemIcon><ChatIcon/></ListItemIcon>
          <ListItemText primary="PrivateChatGPT" />
      </ListItem>):(<></>
      )

      }

        </List>
        <Divider />
        {/*<List>{secondaryListItems}</List>*/}
      </Drawer>
      <main className={classes.content}>
      <div />
        {props.children}
       
      

        <footer className={`${isActive ? 'active' : ''}`}>
        <Container maxWidth='100%'>   
        <Grid direction="row" display="flex" justifyContent="space-between">
            <Grid item xs justifyContent="flex-start" align="center">
            <Copyright />
            </Grid>                      
        </Grid>
        </Container>
      </footer>
      </main>
    </div>
      /*<Container maxWidth="lg" className={classes.container}>
      <Box></Box>
      </Container>*/
  );
}