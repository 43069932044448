import React, { useState } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import history from './../../history';
import getToken from "../../auth/auth";
import LogoPrivategpt from '../../img/logo-privategpt.svg';
import Lightdark from '../../img/light-dark.svg';
import Iconnuxt from '../../img/icon1.svg';
import Icondiscord from '../../img/icon2.svg';
import Icongithub from '../../img/icon3.svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/PermIdentity';
import LockIcon from '@material-ui/icons/Lock';
import CryptoJS from 'crypto-js';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height:'52vh',
    justifyContent:'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform:'capitalize',
    backgroundColor:'#334155',
    color:'#fff',
    '&:hover': {
      backgroundColor: '#25364f!important',
    },
    
  },
  redText: {
    color: 'red',
    marginTop: 19
  },
  inputField:{
    backgroundColor: '#0F172A',
    padding:"0 0 0 24px",
    color:'#fff',
    fontSize:'12px',
    borderRadius:'6px',
    boxShadow: '0px 1px 2px 0px #0000000D',
    boxShadow: '0px 0px 0px 1px #334155 inset',
    boxShadow: '0px 0px 0px 0px #FFFFFF inset',
    border:'1px solid #334155',
    '& label':{
      color:'#334155',
      padding:"0 0 0 24px",
      
    },
    '&:focus legend span':{
      color:'#334155!important',
    },
    '&:hover fieldset': {
      borderColor: 'none!important',
    },
        
  },
  loginHeading:{
    color: '#fff',
    textTransform: 'capitalize',
    fontSize: '48px',
    lineHeight: '48px',
    fontWeight: '700',
    marginBottom: '10px!important',
  },
  loginSubHeading:{
    fontFamily: '"DM Sans", sans-serif',
    fontWeight: '400',
    fontSize: '16px',
    LineHeight:'28px',
    color: '#94A3B8',
  
  },
  inputIcons:{
    position: 'absolute',
    top: '24px',
    left: '10px',
    zIndex:'1',
  },
  footerLine:{
    borderTop:'1px solid #334154!important',
  }

  
  
}));



export default function LoginPage() {
    const classes = useStyles();
    const [alertState, setAlertstate] = useState({
        open: false,
        vertical: "bottom",
        horizontal: "center",
        severity: "success",
        message: "",
    });
    const { vertical, horizontal, open, severity, message } = alertState;
    const [loginObj, setLoginObj] = useState({ logincode: "" });
    const [inputValue, setInputValue] = useState('');

    const handleKeyPress = (event) => {
      
      if (event.key === 'Enter') {
        handleSubmit(); 
      }
    };

    const handleref = async (e) => { 
      e.preventDefault();
    }

    const handleSubmit = async (e) => { 
      
      try { 
        localStorage.clear();
         var key = "dviaapp"+loginObj.logincode+'de' ; 
         var md5key = CryptoJS.MD5(key).toString(); 
          localStorage.clear();
          const webloginresponse = await fetch('https://mail.dil.in/webapi/createuser/signupXgenMail.jsp', {
            method: 'POST', 
            headers: {
                'Content-Type': 'text/plain', 
              },
            body:JSON.stringify({
              flag: "dviaapp",
              reqotp: loginObj.logincode,
              source: "de",
              encr: false,
              key: md5key,
            })
          });  
          
          if (webloginresponse.status==200) {
            const data = await webloginresponse.json();   
            if(data.status)
            {
             var jwtres = await getToken(data.Linfo.Lname);
            
              if (1) {
                  setAlertstate({
                      open: true,
                      vertical: "bottom",
                      horizontal: "center",
                      message: "Login Successfully.",
                      severity: "success",
                  });
                  
                  history.push("/live-search");
              } else {
                  setAlertstate({
                      open: true,
                      vertical: "bottom",
                      horizontal: "center",
                      message: "Login failed. Please try again.",
                      severity: "error",
                  });
              }
            }
            else{
              setAlertstate({
                  open: true,
                  vertical: "bottom",
                  horizontal: "center",
                  message: data.Linfo,
                  severity: "error",
              });
            } 
          }
          else{
            setAlertstate({
              open: true,
              vertical: "bottom",
              horizontal: "center",
              message: "Please try again.",
              severity: "error",
            });
          }

          
      } catch (e) {
          setAlertstate({
              open: true,
              vertical: "bottom",
              horizontal: "center",
              message: "Invalid Data. Please try again.",
              severity: "error",
          });
      }
  };
   
  return (
    <div>
    <Container>
      <Grid container direction="row" justifyContent="space-between">
            <Grid item xs justifyContent="flex-start" align="left" style={{marginTop:'10px'}}>
            <img src={LogoPrivategpt} alt="logo"/>
            </Grid>
            <Grid item xs justifyContent="flex-start" align="right" style={{marginTop:'10px'}}>
            {/*<img src={Lightdark} alt="Light Dark"/>*/}
            </Grid>           
      </Grid>
    </Container>
    <Container component="main" maxWidth="xs">
      
      <CssBaseline />
      
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        
        <Typography style={{marginTop:'50px'}} component="h1" variant="h3" className={classes.loginHeading}>
          Sign In
        </Typography>
       <Typography className={classes.loginSubHeading}>
       Use your web login code
       </Typography>
        <Typography className={classes.redText} >
          {alertState.message}
        </Typography>
        <form onSubmit={handleref} className={classes.form}>
        
        <Box sx={{ display: 'flex', alignItems: 'flex-end', position:'relative' }}>
        <AccountCircle className={classes.inputIcons} sx={{ color: 'action.active', mr: -2, my: 0.5 }} style={{ fill: '#fff' }}/>
          <TextField 
            className={classes.inputField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Login Code"
            name="logincode"
            size="small"            
            autoComplete="off"
            value={inputValue}
            onChange={(e) => {
                setInputValue(e.target.value.toUpperCase());
                setLoginObj({ ...loginObj, logincode: e.target.value.toUpperCase() });
            }}
            onKeyPress={handleKeyPress}
            autoFocus
            
          />
          
          </Box>
          
          
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.submit}
          >
            Sign In
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>           */}
         
        </form>  
   
      </div>
      {/* <Box mt={8}>
        <Copyright />
      </Box> */}
    </Container>
    <Container className={classes.footerLine}>
      <Grid container direction="row" justifyContent="space-between">
            <Grid item xs justifyContent="flex-start" align="left">
             <p style={{color:'white'}}>Copyright © 2023-2024 DataGTP</p>
            </Grid>
            {/*<Grid item xs justifyContent="flex-end"  align="right">
              <div className="" direction="row" justifyContent="space-between">
                <img src={Iconnuxt} alt="logo"/>
                <img src={Icondiscord} alt="logo"/>
                <img src={Icongithub} alt="logo"/>
              </div>
            </Grid> */}          
      </Grid>
    </Container>
    </div>
  );
}