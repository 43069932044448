
import axios from 'axios';
import history from "../history";
const apiUrl = 'http://202.157.82.11/ruhsQP/admin/login'; //your api base url
const aiUrl1 = 'https://privategpt.in'; //your AI base url
const aiUrl = 'https://www.privategpt.in'; //your AI base url
function getHeader() {
    const token = localStorage.getItem("token");
    const expiration = localStorage.getItem("token_exp");
    if (token && expiration * 1000 < Date.now()) {
        history.push("/");
        return {
            "Content-Type": "application/json",
        };
    }
    if (token) {
        return {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            useCredentials: true,
        };
    } else {
        return {
            "Content-Type": "application/json",
        };
    }
}
const api = axios.create({
    baseURL: aiUrl,
    AIURL: aiUrl,
    headers: getHeader(),
})



export default api;
  