import api from "../api/chatai";


//This function is asynchronous and always returns the result of the call
//If Search contains anything, Search users is called, else Get All is called
const getDocuments = async (selectedBrain) => {
    
   
   
    let res = await getAllDocuments(selectedBrain);
    


    //console.log(JSON.stringify(res));

    if (
    res &&  
    res.data &&
    res.data.knowledges &&
    res.data.knowledges.length > 0) {
    return res.data.knowledges;
    } else {
        return [ ]
    }
}


const addAdmins = (data) => {
return api.post(`/admins`,data)
}
const updateAdmins = (id,data) => {
return api.put(`/admins/${id}`,data)
}
const getAllDocuments = (selectedBrain) => {
    
const brainId = selectedBrain;
return api.get(`/knowledge/?brain_id=${brainId}`)
}
const getOneAdmins = (id) => {
return api.get(`/admins/${id}`)
}
const searchDocument = (page,paginator,searchKey) => {
return api.get(`/admins/search/${searchKey}/?page=${page}&paginator=${paginator}`)
}
const deleteDocuments = (id,brainId) => {
return api.delete(`/knowledge/${id}?brain_id=${brainId}`)
}
export {getDocuments,addAdmins,updateAdmins,getAllDocuments,getOneAdmins,searchDocument,deleteDocuments}


